import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { TIERS, TIER_IDS } from '@clubhouse/shared/types';
import ProductModel from 'app/client/core/js/models/product';
import AppConstants, { DISPLAY_CONFIG } from 'app/client/core/js/modules/constants';
export { TIERS } from '@clubhouse/shared/types';
const {
  STANDARD_PLAN_RATES
} = AppConstants;
const TIERS_MAP = {
  /* @deprecated */
  [TIERS.FREE]: {
    id: TIERS.FREE,
    key: TIER_IDS.FREE,
    title: DISPLAY_CONFIG[TIERS.FREE].displayName,
    description: 'Access to our core product',
    costs: {
      month: 0,
      year: 0
    }
  },
  /* @deprecated */
  [TIERS.STANDARD]: {
    id: TIERS.STANDARD,
    key: TIER_IDS.STANDARD,
    title: DISPLAY_CONFIG[TIERS.STANDARD].displayName,
    description: 'Great value for growing teams',
    costs: STANDARD_PLAN_RATES
  },
  /* @deprecated */
  [TIERS.ENTERPRISE]: {
    id: TIERS.ENTERPRISE,
    key: TIER_IDS.ENTERPRISE,
    title: DISPLAY_CONFIG[TIERS.ENTERPRISE].displayName,
    description: 'Build and collaborate at scale',
    costs: null
  },
  [TIERS.FREE_LIMITED]: {
    id: TIERS.FREE_LIMITED,
    key: TIER_IDS.FREE_LIMITED,
    title: DISPLAY_CONFIG[TIERS.FREE_LIMITED].displayName,
    description: 'Plan work, ship quickly. For small teams.'
  },
  [TIERS.TEAM]: {
    id: TIERS.TEAM,
    key: TIER_IDS.TEAM,
    title: DISPLAY_CONFIG[TIERS.TEAM].displayName,
    description: 'Organize and build collaboratively.'
  },
  [TIERS.BUSINESS]: {
    id: TIERS.BUSINESS,
    key: TIER_IDS.BUSINESS,
    title: DISPLAY_CONFIG[TIERS.BUSINESS].displayName,
    description: 'Plan, build, and measure at scale.'
  },
  [TIER_IDS.ENTERPRISE_2]: {
    id: TIERS.ENTERPRISE,
    key: TIER_IDS.ENTERPRISE_2,
    title: DISPLAY_CONFIG[TIERS.ENTERPRISE].displayName,
    description: 'For mature software organizations.',
    costs: null
  }
};
export const TIERS_DISPLAY_KEYS_MAP = {
  ':product.tier/team': TIERS.TEAM,
  ':product.tier/enterprise': TIERS.ENTERPRISE,
  ':product.tier/free-limited': TIERS.FREE_LIMITED,
  ':product.tier/business': TIERS.BUSINESS
};
export const getTier = (product, tier = product.display_key) => getAvailableTiers().find(({
  id
}) => id === tier);

// Get available tiers for the current product's generation
export const getAvailableTiers = () => {
  return [TIERS_MAP[TIER_IDS.FREE_LIMITED], TIERS_MAP[TIER_IDS.TEAM], TIERS_MAP[TIER_IDS.BUSINESS], TIERS_MAP[TIER_IDS.ENTERPRISE_2]];
};
export const isTier = (product, tier) => ProductModel.isTier(product, tier);
export const getMarketingEntitlementList = () => {
  return [{
    title: 'Core Features',
    key: 'core-features',
    features: [{
      title: 'Teams',
      key: 'teams',
      tiers: {
        [TIERS.FREE_LIMITED]: '1',
        [TIERS.TEAM]: 'Up to 5',
        [TIERS.BUSINESS]: 'Unlimited',
        [TIERS.ENTERPRISE]: 'Unlimited'
      }
    }, {
      title: 'Users',
      key: 'users',
      tiers: {
        [TIERS.FREE_LIMITED]: '10',
        [TIERS.TEAM]: 'Unlimited',
        [TIERS.BUSINESS]: 'Unlimited',
        [TIERS.ENTERPRISE]: 'Unlimited'
      }
    }, {
      title: 'Workspaces',
      key: 'workspaces',
      tiers: {
        [TIERS.FREE_LIMITED]: '1',
        [TIERS.TEAM]: '1',
        [TIERS.BUSINESS]: 'Unlimited',
        [TIERS.ENTERPRISE]: 'Unlimited'
      }
    }, {
      title: 'Customizable Workflows',
      key: 'customizable-workflows',
      tiers: {
        [TIERS.FREE_LIMITED]: '1',
        [TIERS.TEAM]: 'Up to 5',
        [TIERS.BUSINESS]: 'Unlimited',
        [TIERS.ENTERPRISE]: 'Unlimited'
      }
    }]
  }, {
    title: 'Build Collaboratively',
    key: 'build-collaboratively',
    features: [{
      title: 'Stories and Epics',
      key: 'stories-and-epics',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Iterations/Sprints',
      key: 'iterations-sprints',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Docs and Docs Templates',
      key: 'docs-and-docs-templates',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Backlog',
      key: 'backlog',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Custom Fields and Labels',
      key: 'custom-fields-and-labels',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Docs Suggestion Mode',
      key: 'docs-suggestion-mode',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Story Templates',
      key: 'story-templates',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Story Work In Progress Limits',
      key: 'story-work-in-progress-limits',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      },
      isNew: true
    }, {
      title: 'Automations',
      key: 'automations',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Advanced Custom Fields',
      key: 'advanced-custom-fields',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: false,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Overlapping Pull Requests',
      key: 'overlapping-pull-requests',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: false,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }]
  }, {
    title: 'Coordinate Roadmaps',
    key: 'coordinate-roadmaps',
    features: [{
      title: 'Roadmaps',
      key: 'roadmaps',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Expanded Roadmaps',
      key: 'expanded-roadmaps',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: false,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }]
  }, {
    title: 'Focus on Goals',
    key: 'focus-on-goals',
    features: [{
      title: 'Tactical Objectives',
      key: 'tactical-objectives',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Strategic Objectives and Key Results',
      key: 'strategic-objectives-and-key-results',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: false,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }]
  }, {
    title: 'Gain Key Insights',
    key: 'gain-key-insights',
    features: [{
      title: 'Epic and Iteration Reports',
      key: 'epic-and-iteration-reports',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Reports page with Metrics Dashboard',
      key: 'reports-page-with-metrics-dashboard',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Additional Charts',
      key: 'additional-charts',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: false,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }]
  }, {
    title: 'Integrate',
    key: 'integrate',
    features: [{
      title: 'GitHub/GitLab/Bitbucket',
      key: 'github-gitlab-bitbucket',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Slack',
      key: 'slack',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Figma',
      key: 'figma',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Miro',
      key: 'miro',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Notion',
      key: 'notion',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Sentry',
      key: 'sentry',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Zendesk',
      key: 'zendesk',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Zapier',
      key: 'zapier',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'And many more',
      key: 'and-many-more',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }]
  }, {
    title: 'Storage',
    key: 'storage',
    features: [{
      title: 'Storage',
      key: 'storage',
      tiers: {
        [TIERS.FREE_LIMITED]: '5G',
        [TIERS.TEAM]: '250G',
        [TIERS.BUSINESS]: '250G',
        [TIERS.ENTERPRISE]: '250G'
      }
    }, {
      title: 'API and Webhook Access',
      key: 'api-and-webhook-access',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }]
  }, {
    title: 'Support & Control',
    key: 'support-and-control',
    features: [{
      title: 'Soc 2 Type II',
      key: 'soc-2-type-ii',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Encryption in transit & at rest',
      key: 'encryption-in-transit-and-at-rest',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Sign in with Google',
      key: 'sign-in-with-google',
      tiers: {
        [TIERS.FREE_LIMITED]: true,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Read-only seats',
      key: 'read-only-seats',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: true,
        [TIERS.BUSINESS]: true,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Custom Terms',
      key: 'custom-terms',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: false,
        [TIERS.BUSINESS]: false,
        [TIERS.ENTERPRISE]: true
      }
    }, {
      title: 'Single sign-on (SSO)',
      key: 'single-sign-on-sso',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: false,
        [TIERS.BUSINESS]: 'Add-on',
        [TIERS.ENTERPRISE]: 'Included'
      }
    }, {
      title: 'SCIM',
      key: 'scim',
      tiers: {
        [TIERS.FREE_LIMITED]: false,
        [TIERS.TEAM]: false,
        [TIERS.BUSINESS]: 'Add-on',
        [TIERS.ENTERPRISE]: 'Included'
      }
    }, {
      title: 'User Roles',
      key: 'user-roles',
      tiers: {
        [TIERS.FREE_LIMITED]: 'Admins and Owners',
        [TIERS.TEAM]: 'Owners, Admins, and Members',
        [TIERS.BUSINESS]: 'Owners, Admins, and Members',
        [TIERS.ENTERPRISE]: 'Owners, Admins, and Members'
      }
    }]
  }];
};