import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { getValue, setValue } from 'utils/localStorage';
const ALL_COLLAPSED = 'ALL_COLLAPSED';
const ALL_EXPANDED = 'ALL_EXPANDED';
const MIXED = 'MIXED';
const getStateFromLocalStorage = id => Boolean(getValue(getToggleKey(id), {
  defaultValue: false
}));
const useExpandedFacets = () => {
  const facets = useReactiveVar(expandedFacets);
  const calculation = Object.values(facets).filter(Boolean).length;
  if (calculation === 0) return ALL_COLLAPSED;
  if (calculation === Object.keys(facets).length) return ALL_EXPANDED;
  return MIXED;
};
export const allFacetsControl = makeVar(false);
export const expandedFacets = makeVar({});
export const subscribeToFacetChange = (key, handleOnChange) => {
  let unsubscribe = null;
  let lastFacets = expandedFacets();

  // The reactive var clears all subscribed listeners after every change.
  // So to have a continous subscription, we need to call onNextChange on every change
  const onChange = facets => {
    if (facets[key] !== lastFacets[key]) handleOnChange(facets[key], facets);
    lastFacets = {
      ...facets
    };
    unsubscribe = expandedFacets.onNextChange(onChange);
  };
  unsubscribe = expandedFacets.onNextChange(onChange);
  return () => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
    }
  };
};
export const getToggleKey = id => `${BRAND.LOCALSTORAGE_NAMESPACE}.Stories.sidebarVisible.${id}`;
export const getStateByKey = id => {
  if (typeof expandedFacets()[id] === 'undefined') {
    const cachedValue = getStateFromLocalStorage(id);
    expandedFacets({
      ...expandedFacets(),
      [id]: cachedValue
    });
    return cachedValue;
  } else {
    return expandedFacets()[id];
  }
};
export const useAllFacetsControl = () => {
  const expanded = useReactiveVar(allFacetsControl);
  const overallFacetsState = useExpandedFacets();
  useEffect(() => {
    if (overallFacetsState === ALL_COLLAPSED && expanded) allFacetsControl(false);
    if (overallFacetsState === ALL_EXPANDED && !expanded) allFacetsControl(true);
  }, [overallFacetsState, expanded]);
  const setExpanded = useCallback(force => {
    const newState = typeof force === 'undefined' ? !expanded : force;
    const facets = expandedFacets();
    const newFacets = Object.keys(facets).reduce((acc, id) => {
      setValue(getToggleKey(id), newState);
      return {
        ...acc,
        [id]: newState
      };
    }, facets);
    expandedFacets(newFacets);
    allFacetsControl(newState);
  }, [expanded]);
  return [expanded, setExpanded];
};
export const useFacetToggle = id => {
  const facets = useReactiveVar(expandedFacets);
  useEffect(() => {
    if (typeof expandedFacets()[id] === 'undefined') {
      expandedFacets({
        ...expandedFacets(),
        [id]: getStateFromLocalStorage(getToggleKey(id))
      });
    }
  }, [id]);
  const isOpen = Boolean(facets[id]);
  return [isOpen, force => {
    const newState = typeof force === 'undefined' ? !isOpen : force;
    setValue(getToggleKey(id), newState);
    expandedFacets({
      ...expandedFacets(),
      [id]: newState
    });
  }];
};