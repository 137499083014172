import { getCurrentUser } from 'data/entity/user';
import { getValue, setValue } from './localStorage';
const LOCAL_STORAGE_KEY = 'lastPathnameViewed';
export function setLastPathViewed() {
  // No need to save the last path viewed if we're not logged in yet
  if (!getCurrentUser()) {
    return;
  }
  try {
    setValue(LOCAL_STORAGE_KEY, window.location.pathname);
  } catch {
    // Do nothing.
  }
}
export function getLastPathViewed() {
  return getValue(LOCAL_STORAGE_KEY);
}