import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { useCallback } from 'react';
import { generatePath, matchPath } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import HotReload from 'app/client/core/js/controllers/hotReload';
import Utils from 'app/client/core/js/modules/utils';
import * as LocalStorage from 'utils/localStorage';
import { fetchUserOrgAndApplicationState } from 'utils/consolidatedFetch';
import Router from '../../app/client/core/js/_frontloader/router';
import Url from '../../app/client/core/js/modules/url';
import ManageUsersController from '../../app/client/settingsShared/js/controllers/manageUsers';
import { getGlobal, setGlobal, useGlobal } from '../globals';
import { getActivityId, openOrFetchAndOpen } from '../storyDialog';
import { PAGE_NAMES, getMatchForPath, getPathForPage } from './paths';
const GLOBAL_INITIAL_PAGE_KEY = 'initialPage';
const GLOBAL_PARTIAL_PAGE_KEY = 'partialPage';
const getPageNameFromPath = ({
  path
}) => {
  return getMatchForPath({
    path
  }).pageName;
};
export const goToManageUsersImportedTab = () => {
  LocalStorage.set('Users.TabViewType', 'imported');
  ManageUsersController.open();
};
export const pushState = (url, title) => {
  return Router.pushState(url, title);
};
export const pushStateWithSlug = (path, title) => {
  return Router.pushStateWithSlug(path, title);
};
export const replaceRouterState = (...args) => Router.replaceState(...args);
export const getSlugPath = () => Url.getSlugPath();
export const getSlug = () => getSlugPath().substr(1);
export const getCurrentOrigin = () => Url.getCurrentOrigin();
export const getCurrentPage = () => Url.getCurrentPage();
export const updateParamInUrl = (id, value) => Url.updateParamInUrl(id, value);
export const updateParamsInUrl = params => Url.updateParamsInUrl(params);
export const getParamFromUrl = id => Url.getParamFromUrl(id);
export const getIntParamFromUrl = name => {
  const value = getParamFromUrl(name);
  const asNumber = Number.parseInt(value);
  return isNaN(asNumber) ? undefined : asNumber;
};
export const getPageFromPathname = pathname => Url.getPageFromPathname(pathname);
export const getPageFromCurrentPathname = (...args) => Url.getPageFromCurrentPathname(...args);
export const getCurrentPathname = (...args) => Url.getCurrentPathname(...args);
export const isStoryURL = Url.isStoryURL;
export const redirect = (...args) => Utils.redirect(...args);
export const getCurrentSlug = Url.getCurrentSlug;
export const parsePath = Url.parsePath;
export const parseLocationSearch = Url.parseLocationSearch;
export const setLastPage = () => HotReload.setLastPage();
export const notRouteException = pathname => Url.notRouteException(pathname);
const navigateToRules = {
  loadStoryDialog: ({
    pathname,
    hash
  }) => {
    const match = matchPath(pathname, getPathForPage(PAGE_NAMES.STORY));
    if (match?.params?.id) {
      openOrFetchAndOpen(Number(match.params.id), getActivityId(hash));
      return true;
    }
    return false;
  }
};

/**
 * WARNING: Use this when needing to call HotReload.loadURL. The HotReload module will clear event listeners and do additional cleanup.
 * The app assumes that the new page will add all the event listeners again.
 *
 * If the new page does not add global listeners to the [Event](@clubhouse/web/app/client/core/js/_frontloader/event.ts) module,
 * some global app behavior might not work as expected, such as file uploads on the story dialog.
 *
 * Prefer `usePushStateOnClick` if the navigation does not require a call to HotReload.loadURL.
 */
export const navigateTo = ({
  url
}) => {
  const {
    pathname,
    hash,
    search
  } = new URL(url, window.location.origin);
  if (Object.values(navigateToRules).some(fn => fn({
    pathname,
    hash
  }))) return;
  return HotReload.loadURL(`${pathname}${search}${hash}`, getPageNameFromPath({
    path: pathname
  }));
};
export const navigateOnClick = url => e => {
  if (isClickedWithModifier(e)) return;
  e.preventDefault();
  navigateTo({
    url
  });
};

/**
 * @deprecated Use generatePathForPage and pass in slug with useWorkspaceSlug
 */
export const generatePathForPageWithSlugAsync = async (pageName, params) => {
  const slug = getSlug();
  if (!slug) {
    await new Promise(resolve => fetchUserOrgAndApplicationState(resolve));
  }
  return generatePathWithSlug(getPathForPage(pageName), params);
};

/**
 * @deprecated Use generatePathForPage and pass in slug with useWorkspaceSlug
 */
export const generatePathForPageWithSlug = (pageName, params) => generatePathWithSlug(getPathForPage(pageName), params);

/**
 * @deprecated Use generatePathForPage and pass in slug with useWorkspaceSlug
 */
export const generatePathWithSlug = (pattern, params) => generatePath(pattern, {
  slug: getSlug(),
  ...params
});
export const useCurrentPage = () => useGlobal(GLOBAL_INITIAL_PAGE_KEY);
export const useCurrentPathname = () => {
  const getCurrentValue = useCallback(() => Url.getCurrentPathname(), []);
  return useGlobal(GLOBAL_INITIAL_PAGE_KEY, getCurrentValue);
};
const DEFAULT_RENDER_ID = uuidv4();
const HOTLOAD_RENDER_ID = 'HotReload.renderId';
export const useSetHotReloadRenderId = () => {
  return useCallback((id = uuidv4()) => setGlobal(HOTLOAD_RENDER_ID, id), []);
};
export const useHotReloadRenderId = () => {
  const getCurrentValue = useCallback(() => getGlobal(HOTLOAD_RENDER_ID) ?? DEFAULT_RENDER_ID, []);
  return useGlobal(HOTLOAD_RENDER_ID, getCurrentValue);
};
export const isCurrentPage = Url.isCurrentPage;
export const replaceStateWith = ({
  from,
  to,
  path
}) => {
  const {
    params
  } = matchPath(path, {
    path: getPathForPage(from)
  }) ?? {};
  window.history.replaceState({}, document.title, generatePathWithSlug(getPathForPage(to), params));
};
export const useCurrentPageInfo = () => {
  const initialPage = useGlobal(GLOBAL_INITIAL_PAGE_KEY);
  const partialPage = useGlobal(GLOBAL_PARTIAL_PAGE_KEY);
  return {
    basePage: initialPage,
    overlayPage: partialPage
  };
};
export const isRedirectedFrom = page => {
  const {
    from
  } = Url.parseLocationSearch();
  return from === page;
};
export const getRelativeHref = (href = window.location.href) => {
  return href.replace(window.location.origin, '');
};