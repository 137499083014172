import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
export const hasSearchParams = (keys, search) => {
  const params = new URLSearchParams(search);
  return keys.some(key => params.has(key));
};
export const getUpdatedSearchParams = (values, search) => {
  const params = new URLSearchParams(search);
  Object.entries(values).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      params.delete(key);
      value.forEach(val => {
        if (val) params.append(key, String(val));
      });
    } else if (value || value === false) {
      params.set(key, String(value));
    } else {
      params.delete(key);
    }
  });
  params.sort();
  return params;
};