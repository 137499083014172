import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
/* eslint-disable no-restricted-globals */
import LocalStorage from 'app/client/core/js/modules/localStorage';
import { getCurrentUserPrefix } from 'data/entity/user';
import escapeRegExp from 'lodash/escapeRegExp';
import { useEffect, useState } from 'react';

/** @deprecated */
export const get = LocalStorage.get;

/** @deprecated */
export const set = LocalStorage.set;

/** @deprecated */
export const remove = LocalStorage.remove;

/** @deprecated */
export const listen = LocalStorage.listen;

/* New implementations. Preferred. */

export const getPrefix = prefix => {
  if (!prefix) return '';
  return prefix === true ? getCurrentUserPrefix() : `${prefix}.`;
};
const withPrefix = (key, prefix) => {
  const prefixStr = getPrefix(prefix);
  return `${prefixStr}${key}`;
};
const maybeParseValue = rawValue => {
  try {
    const item = JSON.parse(rawValue);
    return item;
  } catch {}
  return null;
};
const parseValue = rawValue => {
  const item = maybeParseValue(rawValue);
  if (item) {
    if (!item.exp || item.exp > Date.now()) return item.val;
  }
  return null;
};
export const getValue = (key, {
  prefix,
  raw,
  defaultValue = null
} = {}) => {
  const rawValue = LocalStorage.get(withPrefix(key, prefix));
  if (!rawValue) return defaultValue;
  if (raw) return rawValue;
  const parsedValue = parseValue(rawValue);
  if (parsedValue === null) return defaultValue;
  return parsedValue;
};
export const setValue = (key, val, {
  prefix,
  raw,
  ttl
} = {}) => {
  if (raw) {
    LocalStorage.set(withPrefix(key, prefix), val);
    return;
  }
  const ts = Date.now();
  const item = {
    val,
    ts
  };
  if (ttl) item.exp = ts + ttl;
  LocalStorage.set(withPrefix(key, prefix), JSON.stringify(item));
};
export const removeValue = (key, {
  prefix
} = {}) => {
  LocalStorage.remove(withPrefix(key, prefix));
};
export const useValue = (key, {
  prefix,
  raw,
  defaultValue = null
} = {}) => {
  const [value, setValue] = useState(getValue(key, {
    prefix,
    raw,
    defaultValue
  }));
  useEffect(() => {
    return listen(withPrefix(key, prefix), () => {
      setValue(getValue(key, {
        prefix,
        raw,
        defaultValue
      }));
    });
  }, [key, prefix, raw, defaultValue]);
  return value;
};
const findMatchingKeys = pattern => {
  const found = [];
  for (const [key, value] of Object.entries(localStorage)) {
    if (pattern.test(key)) {
      const item = maybeParseValue(value);
      if (item) {
        // if we don't find a `ts` key, we just use its position in
        // the result of `Object.entries`. This value is roughly
        // stable and can be used to sort and is always less than any
        // actual timestamp.
        found.push({
          key,
          val: item.val,
          ts: item.ts || found.length
        });
      }
    }
  }
  // sort by timestamp
  return found.sort((a, b) => a.ts - b.ts).map(m => [m.key, m.val]);
};
export const useValuesMatching = (patternString, {
  prefix
} = {}) => {
  const prefixString = getPrefix(prefix);
  const escapedPrefixString = escapeRegExp(prefixString);
  const pattern = new RegExp(`^${escapedPrefixString}${patternString}`);
  const found = findMatchingKeys(pattern);
  const [matches, setMatches] = useState(found);
  useEffect(() => {
    return listen(pattern, () => {
      const found = findMatchingKeys(pattern);
      setMatches(found);
    });
  }, [pattern]);
  return matches;
};

/* Util methods: */

export const getLargestItem = () => {
  try {
    return Object.entries(localStorage).reduce((acc, [key, value]) => {
      if (acc.value.length > value.length) {
        return acc;
      }
      return {
        key,
        value
      };
    }, {
      key: '',
      value: ''
    });
  } catch (e) {}
  return {
    key: '',
    value: ''
  };
};
export const isQuotaExceeded = () => {
  const key = String(Math.random());
  try {
    localStorage.setItem(key, 'test-value');
  } catch (e) {
    if (String(e).toLowerCase().includes('quota')) {
      return true;
    }
  }
  try {
    localStorage.removeItem(key);
  } catch (e) {}
  return false;
};
export const getSizeInBytes = () => {
  try {
    return Object.entries(localStorage).reduce((acc, [key, value]) => {
      return acc + (key.length + value.length) * 2; // 2x for utf-16
    }, 0);
  } catch (e) {}
  return 0;
};
export const findKeysStartingWith = (partialKey, {
  prefix,
  raw
} = {}) => {
  const found = [];
  for (const [key, value] of Object.entries(localStorage)) {
    if (key.startsWith(withPrefix(partialKey, prefix))) {
      if (raw) found.push([key, value]);else found.push([key, parseValue(value)]);
    }
  }
  return found;
};

/* eslint-enable no-restricted-globals */