import Is from 'app/client/core/js/modules/is';
import { getCurrentSlug } from './navigation';
export const storiesPage = () => Is.storiesPage();
export const isStoryPage = () => Is.storyPage();
export const withinOrg = () => Is.withinOrg();
export const withinOrgAndWithSlug = () => withinOrg() && !!getCurrentSlug();
export const isLoggedInPage = () => Is.loggedInPage();
export const isRecoveryCodePage = () => Is.isRecoveryCodePage();
export const isKarmaTestEnvironment = () => Is.testEnvironment();
export const ownerOnly = permission_or_profile => Is.ownerOnly(permission_or_profile);
export const adminOrOwner = permission => Is.adminOrOwner(permission);
export const adminOnly = permission => Is.adminOnly(permission);
export const mobile = userAgent => Is.mobile(userAgent);
export const isReadOnly = permission => Is.readOnly(permission);
export const isInternal = () => Is.internal();
export const isProduction = () => Is.production();
export const isStaging = () => Is.staging();
export const isDevelopment = () => Is.development();