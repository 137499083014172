import { ShapesProvider } from '@clubhouse/shapes-ds';
import { useThemeName } from '@clubhouse/shared/utils/theme';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ShapesThemeProvider = ({
  children
}) => _jsx(ShapesProvider, {
  themeName: useThemeName(),
  children: children
});
ShapesThemeProvider.displayName = "ShapesThemeProvider";
export const renderComponentToString = children => {
  const div = document.createElement('div');
  const root = createRoot(div);
  flushSync(() => {
    root.render(_jsx(ShapesThemeProvider, {
      children: children
    }));
  });
  return div.innerHTML;
};
export const htmlStringToElement = string => {
  const template = document.createElement('template');
  template.innerHTML = string.trim();
  return template.content.firstChild;
};