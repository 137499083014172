import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { getById as getEpicById, getStories } from 'data/entity/epic';
import { all as getAllStories, getById as getStoryById } from 'data/entity/story';
function getEpicStories(epicId) {
  const epic = getEpicById(epicId);
  if (!epic) {
    return [];
  }

  // This is absolutely inefficient because we're looping through all stories of a workspace, twice.
  // But this is the safest way so far to capture all stories related to an Epic.
  // This is not supposed to be used for a long time, ideally, we would have this generation server-side.
  const stories = getStories({
    epic: epic
  }).filter(story => !story.archived);
  return stories;
}
function getIterationStories(iterationId) {
  return getAllStories().filter(story => {
    return story.iteration_id === iterationId;
  });
}
export function computeStoryRelationships(entity, entityId, {
  hideCompleted,
  colorScheme
}) {
  const stories = (() => {
    if (entity === 'epic') {
      return getEpicStories(entityId);
    }
    if (entity === 'iteration') {
      return getIterationStories(entityId);
    }
    return [];
  })();
  if (!stories.length) {
    return '';
  }
  const storiesInMermaid = new Set();
  const mermaidSource = stories.reduce((graph, story) => {
    return [...graph, ...story.story_links.filter(link => {
      if (hideCompleted && story.completed) {
        return false;
      }
      return link.subject_id === story.id && link.type === 'subject' && link.verb === 'blocks';
    }).map(link => {
      const objectStory = getStoryById(link.object_id);

      // Let's exclude the object story if it's not part of the entity we're looking at.
      if (entity === 'epic' && objectStory?.epic_id !== entityId) {
        return null;
      }
      if (entity === 'iteration' && objectStory?.iteration_id !== entityId) {
        return null;
      }
      const subjectClass = (() => {
        if (colorScheme === 'blocker-blocked') {
          return `:::mermaid-blocking-story`;
        } else if (colorScheme === 'story-state') {
          return `:::mermaid-story-state-${story.stateObject.type}`;
        }
        return '';
      })();
      const objectClass = (() => {
        if (colorScheme === 'blocker-blocked') {
          return `:::mermaid-blocked-story`;
        } else if (colorScheme === 'story-state') {
          return `:::mermaid-story-state-${objectStory?.stateObject.type ?? 'unknown'}`;
        }
        return '';
      })();

      // We only want to add the story name if it's the first time we use it.
      const subjectName = storiesInMermaid.has(link.subject_id) ? '' : `("${story.name.replaceAll('"', '#quot;')}")`;
      const objectName = storiesInMermaid.has(link.object_id) ? '' : `("${objectStory?.name.replaceAll('"', '#quot;') ?? 'Unknown'}")`;
      storiesInMermaid.add(link.subject_id);
      storiesInMermaid.add(link.object_id);
      return `  ${link.subject_id}${subjectName}${subjectClass} -->|blocks| ${link.object_id}${objectName}${objectClass}`;
    }).filter(link => link !== null)];
  }, ['flowchart TD']).join('\n');
  const clicksAndTooltips = Array.from(storiesInMermaid).map(storyId => {
    const storyGlobalId = getStoryById(storyId)?.global_id;
    if (!storyGlobalId) {
      return '';
    }
    return `  click ${storyId} mermaidStoryTooltipClickHandler "${storyGlobalId}"`;
  }).join('\n');
  return `
\`\`\`mermaid
${mermaidSource}
${clicksAndTooltips}
\`\`\`
`;
}