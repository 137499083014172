import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { isJqueryElement } from './jquery';

// Any block of text over 8kb, skip syntax highlighting
export const SYNTAX_HIGHLIGHT_LIMIT = 8192;
export const highlightElement = async element => {
  const plainElement = isJqueryElement(element) ? element?.[0] : element;
  if (!plainElement) return;
  if (!plainElement.querySelectorAll('pre code:not(.hljs)').length) return;
  const {
    default: hljs
  } = await import(/* webpackChunkName: "hljs" */'static/lib/highlight');
  plainElement.querySelectorAll('pre code:not(.hljs)').forEach(item => {
    if (item.innerHTML.length < SYNTAX_HIGHLIGHT_LIMIT) {
      hljs.highlightElement(item);
    }
  });
};