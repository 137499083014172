import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { useMemo } from 'react';
import { SelectOptionChecked, SelectOptionDefault, SelectOptionDivider, SelectOptionSubLabel, ViewSettingsHeader } from '@clubhouse/shared/components/Select';
import { Switch } from '@clubhouse/shared/components/Switch';
import { useToggleState } from '@clubhouse/shared/hooks';
import { CardDensityOptions } from '@clubhouse/shared/types';
import { capitalize } from '@clubhouse/shared/utils';
import ViewSettingsController from 'app/client/core/js/controllers/viewSettings';
import { useCustomFields } from 'data/entity/customField';
import { useFeature } from 'data/entity/feature';
import { useAppState } from 'utils/appState';
import { noop } from '../nodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CollapseEmptyColumnsOption = props => {
  // Internal toggle state for smoother UI feedback on toggle change
  const {
    value,
    onClick
  } = props;
  const [toggleState, toggle] = useToggleState(Boolean(value));
  return _jsx(SelectOptionDefault, {
    ...props,
    onClick: () => {
      toggle();
      onClick?.();
    },
    isSelected: false,
    children: _jsx(Switch.Label, {
      children: _jsx(Switch, {
        size: "small",
        isChecked: toggleState,
        onChange: noop
      })
    })
  });
};
CollapseEmptyColumnsOption.displayName = "CollapseEmptyColumnsOption";
export const useCardColorMenuItems = onChange => {
  const {
    on: areProjectsEnabled
  } = useFeature({
    id: 'projects'
  });
  const {
    fields
  } = useCustomFields({
    filter: ({
      enabled
    }) => enabled
  });
  const items = useMemo(() => {
    const customFieldOptions = fields.map(({
      name
    }) => ({
      name: capitalize(name),
      value: name
    }));
    return [{
      name: 'Team',
      value: 'team'
    }, {
      name: 'Project',
      value: 'project'
    }, {
      name: 'Story Type',
      value: 'story-type'
    }].filter(option => {
      if (!areProjectsEnabled) return option.value !== 'project';
      return true;
    }).concat(customFieldOptions).map(({
      name,
      value
    }) => ({
      Component: SelectOptionChecked,
      name,
      value,
      key: value,
      onClick() {
        onChange(value);
      }
    }));
  }, [areProjectsEnabled, fields, onChange]);
  return items;
};
export const useViewSettingsMenuItems = () => {
  const [appStateCollapseState] = useAppState({
    appStateKey: 'CollapseEmptyColumns',
    default: false
  });
  const cardColorMenuItems = useCardColorMenuItems(ViewSettingsController.cardColor.set);
  const collapseEmptyColumnsMenuItem = useMemo(() => ({
    name: 'Collapse empty columns',
    Component: CollapseEmptyColumnsOption,
    value: appStateCollapseState,
    key: 'collapse-state',
    onClick() {
      ViewSettingsController.collapseState.toggle();
    }
  }), [appStateCollapseState]);
  const cardDensityMenuItems = useMemo(() => CardDensityOptions.map(density => ({
    Component: SelectOptionChecked,
    name: capitalize(density.replace(/-/g, ' ')),
    value: density,
    key: density,
    onClick() {
      ViewSettingsController.density.set(density);
    }
  })), []);
  const items = useMemo(() => [{
    Component: SelectOptionSubLabel,
    key: 'collapse-columns',
    name: 'Workflow States'
  }, collapseEmptyColumnsMenuItem, {
    Component: SelectOptionDivider,
    name: undefined,
    value: undefined
  }, {
    Component: ViewSettingsHeader,
    key: 'view-density-title',
    name: 'View Density'
  }, ...cardDensityMenuItems, {
    Component: SelectOptionDivider,
    name: undefined,
    value: undefined
  }, {
    Component: ViewSettingsHeader,
    key: 'card-color-title',
    name: 'Card Color'
  }, ...cardColorMenuItems], [cardColorMenuItems, cardDensityMenuItems, collapseEmptyColumnsMenuItem]);
  return items;
};