import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { ContextMenu, ContextMenuDivider, ContextMenuItem, ContextMenuItemLink, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { getHref } from 'components/gql/team/utils/links';
import { update, useGroupWithFetch } from 'data/entity/group';
import { getLoggedInUserPermissionID } from 'data/entity/user';
import { navigateOnClick } from 'utils/navigation';
import { CopyContextMenuItem } from './actions';
import { useContextMenuWithLogging } from './useContextMenuWithLogging';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ToggleGroupMembership = ({
  group
}) => {
  const permissionId = getLoggedInUserPermissionID();
  if (!permissionId) return null;
  const isMember = group.member_ids.includes(permissionId);
  return _jsx(ContextMenuItem, {
    icon: isMember ? 'UserRemove' : 'UserAdd',
    label: isMember ? 'Leave Team' : 'Join Team',
    onClick: async () => {
      try {
        await update({
          id: group.id,
          member_ids: isMember ? group.member_ids.filter(id => id !== permissionId) : [...group.member_ids, permissionId]
        });
      } catch {}
    }
  });
};
ToggleGroupMembership.displayName = "ToggleGroupMembership";
export const TeamContextMenu = ({
  id,
  onRemoveTeamNav,
  onAddTeamNav
}) => {
  const {
    group,
    loading
  } = useGroupWithFetch(id);
  if (loading) return _jsx(ContextMenuLoading, {});
  if (!id || !group) return null;
  return _jsxs(ContextMenu, {
    children: [_jsx(ContextMenuItemLink, {
      icon: "Zoom",
      label: "Go to Team Page",
      href: getHref(id),
      target: "_self",
      onClick: navigateOnClick(getHref(id))
    }), _jsx(ContextMenuDivider, {}), _jsx(CopyContextMenuItem, {
      icon: "Link",
      value: getHref(id, true),
      label: "Link"
    }), _jsx(ToggleGroupMembership, {
      group: group
    }), onRemoveTeamNav || onAddTeamNav ? _jsx(ContextMenuDivider, {}) : null, onRemoveTeamNav ? _jsx(ContextMenuItem, {
      icon: "CloseCircle",
      label: "Remove from menu",
      onClick: onRemoveTeamNav
    }) : null, onAddTeamNav ? _jsx(ContextMenuItem, {
      icon: "AddCircle",
      label: "Add to menu",
      onClick: onAddTeamNav
    }) : null]
  });
};
TeamContextMenu.displayName = "TeamContextMenu";
export const useTeamContextMenu = cmpProps => {
  const {
    props
  } = useContextMenuWithLogging(() => _jsx(TeamContextMenu, {
    ...cmpProps
  }), {
    entityType: 'Team'
  });
  return props;
};