import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { useEffect, useRef } from 'react';
const defaultOnNextProps = ({
  name,
  changes
}) => {
  console.log(`<${name}> render`, changes);
};
export const useOnNextProps = ({
  name,
  props,
  onNextProps = defaultOnNextProps
}) => {
  const previousProps = useRef(props);
  const changes = [...new Set(Object.keys(props).concat(Object.keys(previousProps.current)))].reduce((acc, propName) => {
    if (previousProps.current[propName] !== props[propName]) {
      acc[propName] = {
        previous: previousProps.current[propName],
        current: props[propName]
      };
    }
    return acc;
  }, {});
  let effect = undefined;
  const isInitialRenderRef = useRef(true);
  if (!isInitialRenderRef.current) {
    effect = onNextProps({
      name,
      changes
    });
  }
  useEffect(() => {
    if (effect) {
      effect();
    }
  }, [effect]);
  previousProps.current = props;
  isInitialRenderRef.current = false;
};