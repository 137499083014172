import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.some.js";
import cond from 'lodash/cond';
import constant from 'lodash/constant';
import groupBy from 'lodash/groupBy';
import matches from 'lodash/matches';
import matchesProperty from 'lodash/matchesProperty';
import sortBy from 'lodash/sortBy';
import stubTrue from 'lodash/stubTrue';
import SpaceController from 'app/client/stories/js/controllers/space';
import { getTeamScopedCollectionizeId } from '../data/entity/group';
const groupingFn = cond([[matches({
  name: 'Bugs and Fixes',
  readymade: true
}), constant('bugsAndFixes')], [matches({
  name: 'Backlog',
  readymade: true
}), constant('backlog')], [matchesProperty('readymade', true), constant('readyMades')], [matchesProperty('data.scratch', true), constant('scratch')], [stubTrue, constant('customMades')]]);
export const buildSpacesList = spaces => {
  const {
    backlog = [],
    bugsAndFixes = [],
    readyMades = [],
    scratch = [],
    customMades = []
  } = groupBy(spaces, groupingFn);
  return [...sortBy(readyMades, 'name'), ...backlog, ...bugsAndFixes, ...sortBy(customMades, 'name'), ...scratch];
};
export const pushSpaceState = space => SpaceController.pushState(space);
export const getTeamScopedSpaces = spaces => {
  const teamGlobalId = getTeamScopedCollectionizeId();

  // If there is no teamGlobalId we are not team scoped and should return all spaces
  if (!teamGlobalId) {
    return spaces;
  }
  const teamScopedSpaces = spaces.filter(space => isTeamScopedSpace(space.data.Group, teamGlobalId) || space.readymade || isValidIterationInTeam(space, teamGlobalId));
  return teamScopedSpaces;
};

// We only want to return spaces that have a single team filter that matches the currently scoped team
const isTeamScopedSpace = (teamFilterValues, teamGlobalId) => Array.isArray(teamFilterValues) && teamFilterValues.length === 1 && teamFilterValues[0] === teamGlobalId;
const isValidIterationInTeam = (space, teamGlobalId) => {
  if (!Array.isArray(space?.data?.Filter)) {
    return false;
  }
  const filteredFilters = space.data.Filter.filter(filter => filter.active && filter.type === 'iterationGroup');
  const isSingleIterationInTeamFilter = filteredFilters.length === 1;
  const hasTeamScopeIterationInTeamFilter = filteredFilters.some(filter => filter.value === teamGlobalId);
  return hasTeamScopeIterationInTeamFilter && isSingleIterationInTeamFilter;
};
const visibilityLabels = {
  workspace: 'Shared',
  private: 'Personal'
};
export const getVisibilityLabel = visibility => {
  const label = visibilityLabels[visibility];
  return label ?? '';
};