import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { useCallback, useMemo } from 'react';
import { getQueryParams } from './getQueryParams';
import { getUpdatedSearchParams } from './searchParams';
import { usePageSearch } from './usePageSearch';
import { useReplaceState } from './useReplaceState';
export const useSearchParams = keys => {
  const replaceState = useReplaceState();
  const search = usePageSearch();
  const keysId = keys.join('-');
  // biome-ignore lint/correctness/useExhaustiveDependencies: This ensures that the isValidKey fn is memoized by the values in the keys array and not the array itself
  const isValidKey = useCallback(key => keys.includes(key), [keysId]);
  const setSearchParams = useCallback(params => {
    replaceState(location => {
      const paramsWithValidKeys = Object.entries(params).reduce((acc, [key, value]) => isValidKey(key) ? {
        ...acc,
        [key]: value
      } : acc, {});
      const queryString = getUpdatedSearchParams(paramsWithValidKeys, location.search).toString();
      const url = new URL(location.href);
      url.search = queryString;
      return {
        href: url.toString(),
        state: {}
      };
    });
  }, [isValidKey, replaceState]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: See comment below
  const params = useMemo(() => getQueryParams(keys, search),
  // This should be memoized by each key and the length of the array, which is why keys are joined in the memoization dependencies
  [search, keysId]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: keysId is better.
  const clearSearchParams = useCallback(() => {
    setSearchParams(Object.fromEntries(keys.map(k => [k, null])));
  }, [keysId, setSearchParams]);
  return {
    params,
    setSearchParams,
    clearSearchParams
  };
};