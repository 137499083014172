import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.some.js";
import { useCallback, useMemo } from 'react';
import { findKeysStartingWith, getValue, removeValue, setValue } from 'utils/localStorage';
export const clearSnapshot = key => {
  removeValue(key, {
    prefix: true
  });
};
export const putSnapshot = (key, state) => {
  setValue(key, state, {
    prefix: true
  });
};
export const hasSnapshot = key => {
  return !!getValue(key, {
    prefix: true
  });
};
export const getSnapshot = key => {
  return getValue(key, {
    prefix: true
  });
};
export const useSnapshot = key => {
  const put = useCallback(state => putSnapshot(key, state), [key]);
  const clear = useCallback(() => clearSnapshot(key), [key]);
  return useMemo(() => ({
    clear,
    put
  }), [clear, put]);
};
export const findMatchingSnapshots = query => {
  const found = findKeysStartingWith(query, {
    prefix: true
  });
  return found.filter(([, value]) => {
    if (!value) return false;
    if (Array.isArray(value) && value.length === 0) return false;
    if (typeof value === 'object') return Object.values(value).some(v => !!v);
    return true;
  });
};
export const hasAnySnapshotWithPrefix = query => {
  return findMatchingSnapshots(query).length > 0;
};